import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "../../assets/css/slider.css";
import first from "./assets/images/main-slider/9.jpg"
import second from "./assets/images/main-slider/10.jpg"
import third from "./assets/images/main-slider/11.jpg"
import four from "./assets/images/main-slider/12.jpg"
import five from "./assets/images/main-slider/13.jpg"
import six from "./assets/images/main-slider/14.jpg"
import seven from "./assets/images/main-slider/15.jpg"
import eight from "./assets/images/main-slider/16.jpg"

const slides = [
  {
    title: "Making Your Business Idea",
    description:
      "Our company is one of the world’s leading management consulting firms. Get in touch here asap.",
    btnLearn: "-- Learn More --",
    finan: "Finan",
    dox: "dox",
    btnService: "-- Our Services --",
    image: first
  },
  {
    title: "Making Your Business Idea",
    description:
      "Our company is one of the world’s leading management consulting firms. Get in touch here asap.",
    btnLearn: "-- Learn More --",
    finan: "Finan",
    dox: "dox",
    btnService: "-- Our Services --",
    image: second
  },
  {
    title: "Making Your Business Idea",
    description:
      "Our company is one of the world’s leading management consulting firms. Get in touch here asap.",
    btnLearn: "-- Learn More --",
    finan: "Finan",
    dox: "dox",
    btnService: "-- Our Services --",
    image: third
  },
  {
    title: "Making Your Business Idea",
    description:
      "Our company is one of the world’s leading management consulting firms. Get in touch here asap.",
    btnLearn: "-- Learn More --",
    finan: "Finan",
    dox: "dox",
    btnService: "-- Our Services --",
    image: four
  },
];

const slidesMobile = [
  {
    url: five
  },
  {
    url: six
  },
  {
    url: seven
  },
  {
    url: eight
  },
];

class HomeSlider1 extends Component {
  render() {
    return (
      <>
        <Slider duration={5000} className="slider-wrapper firstslider" autoplay>
          {slides.map((slide, index) => (
            <div
              key={index}
              className="slider-content"
              style={{
                background: `url('${slide.image}') no-repeat left bottom`,
              }}
            ></div>
          ))}
        </Slider>
        <Slider
          duration={5000}
          className="slider-wrapper secondslider"
          autoplay
        >
          {slidesMobile.map((slidesMobile, index) => (
            <div
              key={index}
              className="slider-content"
              style={{
                background: `url('${slidesMobile.url}') no-repeat left bottom`,
              }}
            ></div>
          ))}
        </Slider>
      </>
    );
  }
}
export default HomeSlider1;
