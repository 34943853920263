import { FETCH_FAALIYETEN_SUCCESS } from "../actions/faaliyetEnAction";
const initState = {
    faaliyetEn: [],
};
const faaliyetEnReducer = (state = initState, action) => {
    if (action.type === FETCH_FAALIYETEN_SUCCESS) {
        return {
            ...state,
            faaliyetEn: action.payload,
        };
    }

    return state;
};

export default faaliyetEnReducer;
