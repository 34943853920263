import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import propTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import ReactHtmlParser from "react-html-parser";

const aboutbg = require("./../../assets/images/background/referanslar.jpg");

const References = (props) => {
  const [references, setReferences] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          props.currentLanguageCode === "tr"
            ? "https://server.ozciftay.com.tr/referencesTr"
            : "https://server.ozciftay.com.tr/referencesEn"
        );
        if (!response.ok) {
          throw new Error("API request failed!");
        }
        const data = await response.json();
        const refersAll = data.map((value, index) => (
          <div className="col-lg-4 col-md-6 career-block" key={index}>
            <div className="inner-box" style={{ minHeight: "350px" }}>
              <div className="time">{value.sehir}</div>
              <h3>{value.baslik}</h3>
              <div className="text">{ReactHtmlParser(value.icerik)}</div>
            </div>
          </div>
        ));
        setReferences(refersAll);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [props.currentLanguageCode]); // Run effect whenever currentLanguageCode changes

  return (
    <>
      <Header />

      {/* <!-- Page Banner Section --> */}
      <section className="page-banner">
        <div
          className="page-banner-bg"
          style={{ backgroundImage: "url(" + aboutbg + ")" }}
        ></div>
        <div className="bottom-rotten-curve alternate"></div>

        <div className="auto-container">
          <h1>{props.strings["referanslar"]}</h1>
          <ul className="bread-crumb clearfix">
            <li>
              <Link to={"/#"}>{props.strings["anasayfa"]}</Link>
            </li>
            <li className="active">{props.strings["referanslar"]}</li>
          </ul>
        </div>
      </section>
      {/* <!--End Banner Section --> */}

      {/* <!-- Careers Section --> */}
      <section className="careers-section">
        <div className="auto-container">
          <div className="row">{references}</div>
        </div>
      </section>

      <Footer />
    </>
  );
};

References.propTypes = {
  strings: propTypes.object,
};

export default multilanguage(References);
