import { FETCH_FAALIYETTR_SUCCESS } from "../actions/faaliyetTrAction";
const initState = {
    faaliyetTr: [],
};
const faaliyetTrReducer = (state = initState, action) => {
    if (action.type === FETCH_FAALIYETTR_SUCCESS) {
        return {
            ...state,
            faaliyetTr: action.payload,
        };
    }

    return state;
};

export default faaliyetTrReducer;
