import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import MakinaParkiAccordion from "../element/makinaParkiAccordion";
import Footer from "../layout/footer";
import propTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import DataEn from "../../data/eCompanyEn.json";
import DataTr from "../../data/eCompanyTr.json";

const aboutbg = require("./../../assets/images/background/makina_parki.jpg");

class MakinaParki extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: props.strings,
    };
  }
  render() {
    return (
      <>
        <Header />
        {/* <!--Search Popup--> */}

        {/* <!-- Page Banner Section --> */}
        <section class="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div class="bottom-rotten-curve alternate"></div>

          <div class="auto-container">
            <h1>{this.props.strings["makina_parki"]}</h1>
            <ul class="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>{this.props.strings["anasayfa"]}</Link>
              </li>
              <li class="active">{this.props.strings["makina_parki"]}</li>
            </ul>
          </div>
        </section>
        {/* <!--End Banner Section --> */}

        <MakinaParkiAccordion />

        <Footer />
      </>
    );
  }
}

MakinaParki.propTypes = {
  strings: propTypes.object,
};

export default multilanguage(MakinaParki);
