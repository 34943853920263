import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import propTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";

const aboutbg = require("./../../assets/images/background/404.jpg");

class Error404 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: props.strings,
    };
  }

  render() {
    return (
      <>
        <Header />

        <section class="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div class="bottom-rotten-curve alternate"></div>

          <div class="auto-container">
            <ul class="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>{this.state.strings["anasayfa"]}</Link>
              </li>
              <li class="active">404</li>
            </ul>
          </div>
        </section>

        {/* Error Page */}
        <section class="error-section">
          <div class="auto-container">
            <div class="inner-section">
              <h1>404</h1>
              <h2>{this.state.strings["oopps"]}</h2>
            </div>
          </div>
        </section>

        {/* Error Page */}

        <Footer />
      </>
    );
  }
}

Error404.propTypes = {
  strings: propTypes.object,
};

export default multilanguage(Error404);
