import React, { Component } from "react";
import { Link } from "react-router-dom";
import propTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

const images = [
  "/assets/images/gallery/list/hammadde/cimento-hammadde-1.jpg",
  "/assets/images/gallery/list/hammadde/cimento-hammadde-2.jpg",
  "/assets/images/gallery/list/hammadde/cimento-hammadde-3.jpg",
  "/assets/images/gallery/list/hammadde/hammadde-4.jpg",
  "/assets/images/gallery/list/agrega/agrega-1.jpg",
  "/assets/images/gallery/list/agrega/agrega-2.jpg",
  "/assets/images/gallery/list/agrega/agrega-3.jpg",
  "/assets/images/gallery/list/agrega/agrega-4.jpg",
];

const images2 = [
  "/assets/images/gallery/list/hammadde/cimento-hammadde-1.jpg",
  "/assets/images/gallery/list/hammadde/cimento-hammadde-2.jpg",
  "/assets/images/gallery/list/hammadde/cimento-hammadde-3.jpg",
  "/assets/images/gallery/list/hammadde/hammadde-4.jpg",
  "/assets/images/gallery/list/agrega/agrega-1.jpg",
  "/assets/images/gallery/list/agrega/agrega-2.jpg",
  "/assets/images/gallery/list/agrega/agrega-3.jpg",
  "/assets/images/gallery/list/agrega/agrega-4.jpg",
];
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: props.strings,
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <>
        <footer class="main-footer">
          <div class="auto-container">
            <div class="widgets-section">
              <div class="row clearfix">
                <div class="column col-lg-4">
                  <div class="footer-widget logo-widget">
                    <div class="widget-content">
                      <div class="footer-logo">
                        <Link to="index.html">
                          <img
                            class=""
                            src={require("../../assets/images/ozciftaylogo.png")}
                            alt=""
                          />
                        </Link>
                      </div>
                      <div class="text">
                        {this.props.strings["footer_ozet"]}
                      </div>
                      <ul class="social-links clearfix">
                        <li>
                          <Link to={"/#"}>
                            <span class="fab fa-facebook-f"></span>
                          </Link>
                        </li>
                        <li>
                          <Link to={"/#"}>
                            <span class="fab fa-twitter"></span>
                          </Link>
                        </li>
                        <li>
                          <Link to={"/#"}>
                            <span class="fab fa-vimeo-v"></span>
                          </Link>
                        </li>
                        <li>
                          <Link to={"/#"}>
                            <span class="fab fa-instagram"></span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="column col-lg-4">
                  <div class="footer-widget links-widget">
                    <div class="widget-content">
                      <h3>{this.props.strings["hizli_linkler"]}</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <ul>
                            <li>
                              <Link to={"/hakkimizda"}>
                                {this.props.strings["hakkimizda"]}
                              </Link>
                            </li>
                            <li>
                              <Link to={"/kalite-politikamiz"}>
                                {
                                  this.props.strings[
                                    "kalite_politikamiz_baslik"
                                  ]
                                }
                              </Link>
                            </li>
                            <li>
                              <Link to={"/kvkk"}>
                                {this.props.strings["kvkk"]}
                              </Link>
                            </li>
                            <li>
                              <Link to={"/prensiplerimiz"}>
                                {this.props.strings["prensiplerimiz"]}
                              </Link>
                            </li>
                            <li>
                              <Link to={"/referanslar"}>
                                {this.props.strings["referanslar"]}
                              </Link>
                            </li>
                            <li style={{ textTransform: "lowercase" }}>
                              <Link to={"/e-company"}>
                                {this.props.strings["e_sirket_bilgilerimiz"]}
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6">
                          <ul>
                            <li>
                              <Link to={"/devam-eden-projelerimiz"}>
                                {" "}
                                {this.props.strings["devam_eden_projelerimiz"]}
                              </Link>
                            </li>
                            <li>
                              <Link to={"/tamamlanan-projelerimiz"}>
                                {" "}
                                {this.props.strings["tamamlanan_projelerimiz"]}
                              </Link>
                            </li>
                            <li>
                              <Link to={"/makinaparki"}>
                                {" "}
                                {this.props.strings["makina_parki"]}
                              </Link>
                            </li>
                            <li>
                              <Link to={"/faaliyet-alanlari"}>
                                {" "}
                                {this.props.strings["faaliyet_alanlari"]}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column col-lg-4">
                  <div class="footer-widget instagram-widget">
                    <h3>{this.props.strings["photo_showcase"]}</h3>
                    <div class="inner-box">
                      <div class="wrapper-box">
                        {images.map((image, index) => (
                          <div class="image">
                            <Link
                              onClick={() =>
                                this.setState({
                                  photoIndex: index,
                                  isOpen: true,
                                })
                              }
                            >
                              <img
                                src={image}
                                class="lazy-image owl-lazy"
                                style={{ width: "100px" }}
                              />
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="auto-container">
            <div class="footer-bottom">
              <div class="copyright">
                <a target={"_blank"} href="https://osicrew.com">
                  {" "}
                  Copyright {new Date().getFullYear()} © Özçiftay, All rights
                  Reserved. Designed by OsiCrew
                </a>
              </div>
            </div>
          </div>
          {isOpen && (
            <Lightbox
              mainSrc={images2[photoIndex]}
              nextSrc={images2[(photoIndex + 1) % images2.length]}
              prevSrc={
                images2[(photoIndex + images2.length - 1) % images2.length]
              }
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (photoIndex + images2.length - 1) % images2.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images2.length,
                })
              }
            />
          )}
        </footer>
      </>
    );
  }
}
Footer.propTypes = {
  strings: propTypes.object,
};

export default multilanguage(Footer);
/**
 *    <div class="image">
                          <img
                            class="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-1.jpg")}
                            alt=""
                          />

                          <div class="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-1.jpg"}
                              class="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span class="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                        <div class="image">
                          <img
                            class="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-2.jpg")}
                            alt=""
                          />
                          <div class="overlay-link">
                            <a
                              href={"/assets/images/gallery/gallery-2.jpg"}
                              class="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span class="fa fa-plus"></span>
                            </a>
                          </div>
                        </div>
                        <div class="image">
                          <img
                            class="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-3.jpg")}
                            alt=""
                          />
                          <div class="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-3.jpg"}
                              class="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span class="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                        <div class="image">
                          <img
                            class="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-4.jpg")}
                            alt=""
                          />
                          <div class="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-4.jpg"}
                              class="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span class="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                        <div class="image">
                          <img
                            class="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-5.jpg")}
                            alt=""
                          />
                          <div class="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-5.jpg"}
                              class="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span class="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                        <div class="image">
                          <img
                            class="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-6.jpg")}
                            alt=""
                          />
                          <div class="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-6.jpg"}
                              class="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span class="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                        <div class="image">
                          <img
                            class="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-7.jpg")}
                            alt=""
                          />
                          <div class="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-7.jpg"}
                              class="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span class="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                        <div class="image">
                          <img
                            class="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-8.jpg")}
                            alt=""
                          />
                          <div class="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-8.jpg"}
                              class="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span class="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
                        <div class="image">
                          <img
                            class="lazy-image owl-lazy"
                            src={require("../../assets/images/gallery/gallery-9.jpg")}
                            alt=""
                          />
                          <div class="overlay-link">
                            <Link
                              to={"/assets/images/gallery/gallery-9.jpg"}
                              class="lightbox-image"
                              data-fancybox="gallery"
                            >
                              <span class="fa fa-plus"></span>
                            </Link>
                          </div>
                        </div>
 */
