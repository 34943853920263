import React, { useEffect } from "react";
import Router from "./markup/router";
import "./assets/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/color.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Index from "./markup/pages/index";
import Error404 from "./markup/pages/404";
import About from "./markup/pages/about";
import References from "./markup/pages/References";
import Contact from "./markup/pages/contact";
import BackToTop from "./markup/layout/backToTop";
import quality_policy from "./markup/pages/quality_policy";
import kvkk from "./markup/pages/kvkk";
import ourPrinciples from "./markup/pages/our-principles";
import eCompany from "./markup/pages/e-company";
import OngoingProject from "./markup/pages/ongoingProjects";
import finishedProjects from "./markup/pages/finishedProjects";
import MakinaParki from "./markup/pages/makinaparki";
import faaliyetAlanları from "./markup/pages/faaliyetAlanları";
import Team from "./markup/pages/team";
import Career from "./markup/pages/career";
import Galeri from "./markup/pages/galeri";
import ScroolToTop from "./markup/element/ScroolToTop";

import AracTakip from "./markup/pages/arac_takip";

const App = (props) => {
  props.dispatch(
    loadLanguages({
      languages: {
        en: require("./translations/english.json"),
        tr: require("./translations/turkish.json"),
      },
    })
  );

  return (
    <BrowserRouter basename={"/"}>
      {/* <BrowserRouter> */}
      <div class="page-wrapper">
        <ScroolToTop />
        <Switch>
          <Route exact path="/" component={Index} />
          <Route path="/kalite-politikamiz" component={quality_policy} />
          <Route path="/404" component={Error404} />
          <Route path="/hakkimizda" component={About} />
          <Route path="/referanslar" component={References} />
          <Route path="/iletisim" component={Contact} />
          <Route path="/kariyer" component={Career} />
          <Route path="/galeri" component={Galeri} />
          <Route path="/kvkk" component={kvkk} />
          <Route path="/prensiplerimiz" component={ourPrinciples} />
          <Route path="/e-sirket-bilgilerimiz" component={eCompany} />
          <Route path="/devam-eden-projelerimiz" component={OngoingProject} />
          <Route path="/makinaparki" component={MakinaParki} />
          <Route path="/faaliyet-alanlari" component={faaliyetAlanları} />
          <Route path="/yonetim-kadrosu" component={Team} />
          <Route path="/arac-takip" component={AracTakip} />
          <Route path="/tamamlanan-projelerimiz" component={finishedProjects} />
          <Route exact component={Error404} />
        </Switch>

        <BackToTop />
      </div>
    </BrowserRouter>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
