import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import Menu from "./menu";
import { changeLanguage } from "redux-multilanguage";

const Header = ({ strings, dispatch, currentLanguageCode }) => {
  const [scrolled, setScrolled] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [value, setValue] = useState("");
  const changeLanguageTrigger = (e) => {
    e.preventDefault();
    const languageCode = e.target.value;
    dispatch(changeLanguage(languageCode));
  };
  useEffect(() => {
    var btn = document.querySelector(".mobile-nav-toggler");
    var closeBtn = document.querySelector(".close-btn");
    var body = document.getElementsByTagName("body")[0];
    var sidemenuiitem = document.querySelector(".sidemenuiitem");

    function addFunc() {
      return body.classList.add("mobile-menu-visible");
    }
    function closeFunc() {
      return body.classList.remove("mobile-menu-visible");
    }

    btn.addEventListener("click", addFunc);
    closeBtn.addEventListener("click", closeFunc);
    sidemenuiitem.addEventListener("click", closeFunc);

    window.addEventListener("scroll", handleScroll);
    // window.removeEventListener("scroll", handleScroll);
  });

  const handleScroll = (event) => {
    if (window.scrollY > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  return (
    <header class={scrolled ? "main-header fixed-header" : "main-header"}>
      <div class="header-top">
        <div class="auto-container">
          <div class="inner">
            <div class="top-left">
              <div class="logo-box">
                <div class="logo">
                  <Link to={"/#"}>
                    <img
                      src={require("../../assets/images/ozciftaylogo.png")}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>

            <div class="top-middile">
              <div class="contact-info">
                <div class="single-info">
                  <div class="icon-box">
                    <i class="flaticon-call-1"></i>
                  </div>
                  <div class="text">{strings["telefon_numarasi"]}</div>

                  <h4>
                    <a href={"tel:" + "+90(312)4727677"}>+90(312)472 76 77</a>
                  </h4>
                </div>
                <div class="single-info">
                  <div class="icon-box">
                    <i class="flaticon-email-4"></i>
                  </div>
                  <div class="text">{strings["email_adresi"]}</div>
                  <h4>
                    <a href={"mailto:" + "info@ozciftay.com.tr"}>
                      info@ozciftay.com.tr
                    </a>
                  </h4>
                </div>
              </div>
            </div>

            <div class="top-right">
              <Link to={"/iletisim"} class="theme-btn btn-style-two">
                <span class="btn-title">{strings["iletisim_formu"]}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div class="header-upper">
        <div class="auto-container">
          <div class="inner-container">
            <div class="nav-outer clearfix">
              <div class="mobile-nav-toggler">
                <span class="icon fal fa-bars"></span>
              </div>

              <nav class="main-menu navbar-expand-md navbar-light">
                <div
                  class="collapse navbar-collapse show clearfix"
                  id="navbarSupportedContent"
                >
                  <ul class="navigation clearfix">
                    <li>
                      <Link to={"/"}> {strings["anasayfa"]}</Link>
                    </li>
                    <li class="dropdown">
                      <Link to={"/hakkimizda"}> {strings["kurumsal"]}</Link>
                      <ul>
                        <li>
                          <Link to={"/hakkimizda"}>
                            {strings["hakkimizda"]}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/kalite-politikamiz"}>
                            {strings["kalite_politikamiz_baslik"]}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/kvkk"}>{strings["kvkk"]}</Link>
                        </li>
                        <li>
                          <Link to={"/prensiplerimiz"}>
                            {strings["prensiplerimiz"]}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/referanslar"}>
                            {strings["referanslar"]}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/e-sirket-bilgilerimiz"}>
                            {strings["e_sirket_bilgilerimiz"]}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/yonetim-kadrosu"}>
                            {strings["yonetim_kadrosu"]}
                          </Link>
                        </li>
                        <li>
                          <a
                            target={"_blank"}
                            href={"http://ozciftay.com.tr/katalog.pdf"}
                          >
                            {strings["e_katalog_baslik"]}
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to={"/faaliyet-alanlari"}>
                        {strings["faaliyet_alanlari"]}
                      </Link>
                    </li>
                    <li>
                      <Link to={"/makinaparki"}>{strings["makina_parki"]}</Link>
                    </li>
                    <li class="dropdown">
                      <Link to={"/devam-eden-projelerimiz"}>
                        {" "}
                        {strings["projelerimiz"]}
                      </Link>
                      <ul>
                        <li>
                          <Link to={"/devam-eden-projelerimiz"}>
                            {strings["devam_eden_projelerimiz"]}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/tamamlanan-projelerimiz"}>
                            {strings["tamamlanan_projelerimiz"]}
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to={"/galeri"}> {strings["galeri"]}</Link>
                    </li>
                    <li>
                      <Link to={"/kariyer"}>{strings["career"]}</Link>
                    </li>
                    <li>
                      <Link to={"/iletisim"}>{strings["contact"]}</Link>
                    </li>
                    <li>
                      {currentLanguageCode == "tr" ? (
                        <span
                          onClick={(e) => {
                            dispatch(changeLanguage("en"));
                          }}
                        >
                          EN
                        </span>
                      ) : (
                        <span
                          onClick={(e) => {
                            dispatch(changeLanguage("tr"));
                          }}
                        >
                          TR
                        </span>
                      )}
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div
        class={
          scrolled ? "sticky-header animated slideInDown" : "sticky-header"
        }
      >
        <div class="auto-container clearfix">
          <div class="logo pull-left">
            <Link to={"/#"} title="">
              <img
                src={require("../../assets/images/ozciftaylogo.png")}
                alt=""
                title=""
              />
            </Link>
          </div>

          <div class="pull-right">
            <nav class="main-menu clearfix">
              <div
                class="collapse navbar-collapse show clearfix"
                id="navbarSupportedContent"
              >
                <ul class="navigation clearfix">
                  <li>
                    <Link to={"/"}> {strings["anasayfa"]}</Link>
                  </li>
                  <li class="dropdown">
                    <Link to={"/hakkimizda"}> {strings["kurumsal"]}</Link>
                    <ul>
                      <li>
                        <Link to={"/hakkimizda"}>{strings["hakkimizda"]}</Link>
                      </li>
                      <li>
                        <Link to={"/kalite-politikamiz"}>
                          {strings["kalite_politikamiz_baslik"]}
                        </Link>
                      </li>
                      <li>
                        <Link to={"/kvkk"}>{strings["kvkk"]}</Link>
                      </li>
                      <li>
                        <Link to={"/prensiplerimiz"}>
                          {strings["prensiplerimiz"]}
                        </Link>
                      </li>
                      <li>
                        <Link to={"/referanslar"}>
                          {strings["referanslar"]}
                        </Link>
                      </li>
                      <li>
                        <Link to={"/e-sirket-bilgilerimiz"}>
                          {strings["e_sirket_bilgilerimiz"]}
                        </Link>
                      </li>
                      <li>
                        <Link to={"/yonetim-kadrosu"}>
                          {strings["yonetim_kadrosu"]}
                        </Link>
                      </li>
                      <li>
                        <a
                          target={"_blank"}
                          href={"http://ozciftay.com.tr/katalog.pdf"}
                        >
                          {strings["e_katalog_baslik"]}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to={"/faaliyet-alanlari"}>
                      {strings["faaliyet_alanlari"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={"/makinaparki"}>{strings["makina_parki"]}</Link>
                  </li>
                  <li class="dropdown">
                    <Link to={"/devam-eden-projelerimiz"}>
                      {" "}
                      {strings["projelerimiz"]}
                    </Link>
                    <ul>
                      <li>
                        <Link to={"/devam-eden-projelerimiz"}>
                          {strings["devam_eden_projelerimiz"]}
                        </Link>
                      </li>
                      <li>
                        <Link to={"/tamamlanan-projelerimiz"}>
                          {strings["tamamlanan_projelerimiz"]}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to={"/kariyer"}>{strings["career"]}</Link>
                  </li>
                  <li>
                    <Link to={"/iletisim"}>{strings["contact"]}</Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn">
          <span class="icon flaticon-cancel"></span>
        </div>

        <nav class="menu-box">
          <div class="nav-logo">
            <Link to={"/#"}>
              <img src="assets/images/ozciftaylogo.png" alt="" title="" />
            </Link>
          </div>
          <div class="menu-outer">
            <div
              class="collapse navbar-collapse show clearfix"
              id="navbarSupportedContent"
            >
              <ul class="navigation clearfix">
                <li>
                  <span
                    onClick={() => {
                      var body = document.getElementsByTagName("body")[0];
                      body.classList.remove("mobile-menu-visible");
                    }}
                  >
                    <Link to={"/"} className="sidemenuiitem text-white">
                      {" "}
                      {strings["side_home"]}
                    </Link>
                  </span>
                </li>
                <li class="dropdown">
                  <span
                    className="dropdown"
                    onClick={() => setVisible(!visible)}
                  >
                    {" "}
                    {strings["side_corporate"]}
                  </span>
                  {}
                </li>
                {visible ? (
                  <Fragment>
                    <li>
                      <span
                        onClick={() => {
                          var body = document.getElementsByTagName("body")[0];
                          body.classList.remove("mobile-menu-visible");
                        }}
                      >
                        <Link className="ml-4 text-white" to={"/hakkimizda"}>
                          - {strings["side_about_us"]}
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => {
                          var body = document.getElementsByTagName("body")[0];
                          body.classList.remove("mobile-menu-visible");
                        }}
                      >
                        <Link
                          className="ml-4 text-white"
                          to={"/kalite-politikamiz"}
                        >
                          - {strings["side_our_quality"]}
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => {
                          var body = document.getElementsByTagName("body")[0];
                          body.classList.remove("mobile-menu-visible");
                        }}
                      >
                        <Link className="ml-4 text-white" to={"/kvkk"}>
                          - {strings["side_kvkk"]}
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => {
                          var body = document.getElementsByTagName("body")[0];
                          body.classList.remove("mobile-menu-visible");
                        }}
                      >
                        <Link
                          className="ml-4 text-white"
                          to={"/prensiplerimiz"}
                        >
                          - {strings["side_principles"]}
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => {
                          var body = document.getElementsByTagName("body")[0];
                          body.classList.remove("mobile-menu-visible");
                        }}
                      >
                        <Link className="ml-4 text-white" to={"/referanslar"}>
                          - {strings["side_references"]}
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => {
                          var body = document.getElementsByTagName("body")[0];
                          body.classList.remove("mobile-menu-visible");
                        }}
                      >
                        <Link
                          className="ml-4 text-white"
                          to={"/e-sirket-bilgilerimiz"}
                        >
                          - {strings["side_ecompany"]}
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => {
                          var body = document.getElementsByTagName("body")[0];
                          body.classList.remove("mobile-menu-visible");
                        }}
                      >
                        <Link
                          className="ml-4 text-white"
                          to={"/yonetim-kadrosu"}
                        >
                          - {strings["side_management"]}
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => {
                          var body = document.getElementsByTagName("body")[0];
                          body.classList.remove("mobile-menu-visible");
                        }}
                      >
                        <a
                          className="ml-4 text-white"
                          target={"_blank"}
                          href={"http://ozciftay.com.tr/katalog.pdf"}
                        >
                          - {strings["side_catalog"]}
                        </a>
                      </span>
                    </li>
                  </Fragment>
                ) : null}
                <li>
                  <Link to={"/faaliyet-alanlari"}>
                    {strings["side_ourfield"]}
                  </Link>
                </li>
                <li>
                  <Link to={"/makinaparki"}>{strings["sidemachine"]}</Link>
                </li>
                <li class="dropdown">
                  <span
                    className="dropdown"
                    onClick={() => setVisible2(!visible2)}
                  >
                    {" "}
                    {strings["side_ourproject"]}
                  </span>
                </li>
                {visible2 ? (
                  <Fragment>
                    <li>
                      {" "}
                      <span
                        onClick={() => {
                          var body = document.getElementsByTagName("body")[0];
                          body.classList.remove("mobile-menu-visible");
                        }}
                      >
                        <Link
                          className="ml-4 text-white"
                          to={"/devam-eden-projelerimiz"}
                        >
                          - {strings["side_ongoing"]}
                        </Link>
                      </span>
                    </li>
                    <li>
                      {" "}
                      <span
                        onClick={() => {
                          var body = document.getElementsByTagName("body")[0];
                          body.classList.remove("mobile-menu-visible");
                        }}
                      >
                        <Link
                          className="ml-4 text-white"
                          to={"/tamamlanan-projelerimiz"}
                        >
                          - {strings["side_finished"]}
                        </Link>
                      </span>
                    </li>
                  </Fragment>
                ) : null}

                <li>
                  <span
                    onClick={() => {
                      var body = document.getElementsByTagName("body")[0];
                      body.classList.remove("mobile-menu-visible");
                    }}
                  >
                    <Link className=" text-white" to={"/galeri"}>
                      {" "}
                      {strings["side_gallery"]}
                    </Link>
                  </span>
                </li>
                <li>
                  {" "}
                  <span
                    onClick={() => {
                      var body = document.getElementsByTagName("body")[0];
                      body.classList.remove("mobile-menu-visible");
                    }}
                  >
                    <Link className=" text-white" to={"/kariyer"}>
                      {strings["side_career"]}
                    </Link>
                  </span>
                </li>

                <li>
                  <Link to={"/iletisim"}>
                    <span
                      onClick={() => {
                        var body = document.getElementsByTagName("body")[0];
                        body.classList.remove("mobile-menu-visible");
                      }}
                    >
                      {strings["side_contact"]}
                    </span>
                  </Link>
                </li>
                <li>
                  {currentLanguageCode == "tr" ? (
                    <span
                      onClick={(e) => {
                        dispatch(changeLanguage("en"));
                      }}
                    >
                      EN
                    </span>
                  ) : (
                    <span
                      onClick={(e) => {
                        dispatch(changeLanguage("tr"));
                      }}
                    >
                      TR
                    </span>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};
Header.propTypes = {
  strings: PropTypes.object,
  currentLanguageCode: PropTypes.string,
  dispatch: PropTypes.func,
};

export default multilanguage(Header);
