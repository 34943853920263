import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import { relativeValue } from "react-range";
const images = [
  {
    id: 1,
    name: "1",
    description: "ÖzÇiftay",
    cimento: true,
    imgUrl: "/assets/images/gallery/list/hammadde/cimento-hammadde-1.jpg",
  },
  {
    id: 2,
    name: "2",
    description: "ÖzÇiftay",
    cimento: true,
    imgUrl: "/assets/images/gallery/list/hammadde/cimento-hammadde-2.jpg",
  },
  {
    id: 3,
    name: "3",
    description: "ÖzÇiftay",
    cimento: true,
    imgUrl: "/assets/images/gallery/list/hammadde/hammadde-4.jpg",
  },
  {
    id: 4,
    name: "4",
    description: "ÖzÇiftay",
    cimento: true,
    imgUrl: "/assets/images/gallery/list/hammadde/hammadde-5.jpg",
  },
  {
    id: 5,
    name: "5",
    description: "ÖzÇiftay",
    cimento: true,
    imgUrl: "/assets/images/gallery/list/hammadde/cimento-hammadde-3.jpg",
  },
  {
    id: 6,
    name: "6",
    description: "ÖzÇiftay",
    agrega: true,
    imgUrl: "/assets/images/gallery/list/agrega/agrega-1.jpg",
  },
  {
    id: 7,
    name: "7",
    description: "ÖzÇiftay",
    agrega: true,
    imgUrl: "/assets/images/gallery/list/agrega/agrega-2.jpg",
  },
  {
    id: 8,
    name: "8",
    description: "ÖzÇiftay",
    agrega: true,
    imgUrl: "/assets/images/gallery/list/agrega/agrega-3.jpg",
  },
  {
    id: 9,
    name: "9",
    description: "ÖzÇiftay",
    agrega: true,
    imgUrl: "/assets/images/gallery/list/agrega/agrega-4.jpg",
  },

  {
    id: 11,
    name: "11",
    description: "ÖzÇiftay",
    dekapaj: true,
    imgUrl: "/assets/images/gallery/list/dekapaj/dekapaj-2.jpg",
  },
  {
    id: 12,
    name: "12",
    description: "ÖzÇiftay",
    otoyol: true,
    imgUrl: "/assets/images/gallery/list/otoyol/otoyol-1.jpg",
  },
  {
    id: 13,
    name: "13",
    description: "ÖzÇiftay",
    otoyol: true,
    imgUrl: "/assets/images/gallery/list/otoyol/otoyol-2.jpg",
  },
  {
    id: 14,
    name: "14",
    description: "ÖzÇiftay",
    gecit: true,
    imgUrl: "/assets/images/gallery/list/alt-ust/alt-ust.jpg",
  },
  {
    id: 15,
    name: "15",
    description: "ÖzÇiftay",
    kopru: true,
    imgUrl: "/assets/images/gallery/list/kavsak/kavsak-1.jpg",
  },
  {
    id: 16,
    name: "16",
    description: "ÖzÇiftay",
    kopru: true,
    imgUrl: "/assets/images/gallery/list/kavsak/kavsak-2.jpg",
  },
  {
    id: 17,
    name: "17",
    description: "ÖzÇiftay",
    kopru: true,
    imgUrl: "/assets/images/gallery/list/kavsak/kavsak-3.jpg",
  },
  {
    id: 18,
    name: "18",
    description: "ÖzÇiftay",
    tunel: true,
    imgUrl: "/assets/images/gallery/list/tunel/tunel.jpg",
  },
  {
    id: 19,
    name: "19",
    description: "ÖzÇiftay",
    baraj: true,
    imgUrl: "/assets/images/gallery/list/baraj/barajlar-1.jpg",
  },
  {
    id: 20,
    name: "20",
    description: "ÖzÇiftay",
    baraj: true,
    imgUrl: "/assets/images/gallery/list/baraj/barajlar-2.jpg",
  },
  {
    id: 21,
    name: "21",
    description: "ÖzÇiftay",
    termik: true,
    imgUrl: "/assets/images/gallery/list/termik/termik-1.jpg",
  },
  {
    id: 22,
    name: "22",
    description: "ÖzÇiftay",
    termik: true,
    imgUrl: "/assets/images/gallery/list/termik/termik-2.jpg",
  },
];

const FILTER_DEFS = {
  CIMENTO: (image) => image.cimento,
  AGREGA: (image) => image.agrega,
  DEKAPAJ: (image) => image.dekapaj,
  OTOYOL: (image) => image.otoyol,
  GECIT: (image) => image.gecit,
  KOPRU: (image) => image.kopru,
  TUNEL: (image) => image.tunel,
  BARAJ: (image) => image.baraj,
  TERMIK: (image) => image.termik,
  NONE: (image) => image,
};

const PortfolioFilter1 = (props) => {
  const [state, setState] = useState({
    list: images,
    filterKey: "NONE",
  });

  const [styleBtn, setStyleBtn] = useState("acli1");
  const [isOpen, setisOpen] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0);

  const { list, filterKey } = state;
  const filteredList = list.filter(FILTER_DEFS[filterKey]);
  return (
    <>
      <section class="gallery-section">
        <div class="sortable-masonry">
          <div class="auto-container">
            <div class="row m-0 justify-content-md-between align-items-center">
              <div class="sec-title">
                <div class="sub-title">{props.strings["photo_showcase"]}</div>
                {/* <h2>Yapılan İşler</h2> */}
              </div>
              {/* <!--Filter--> */}
              <div
                class="filters text-center wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <ul class="filter-tabs filter-btns">
                  <li
                    class={styleBtn === "acli1" ? "active  filter" : "filter"}
                    onClick={() => {
                      setStyleBtn("acli1");
                      setState({ ...state, filterKey: "NONE" });
                    }}
                  >
                    <span>{props.strings["tumu"]}</span>
                  </li>
                  <li
                    class={styleBtn === "acli0" ? "active  filter" : "filter"}
                    onClick={() => {
                      setStyleBtn("acli0");
                      setState({ ...state, filterKey: "CIMENTO" });
                    }}
                  >
                    <span>{props.strings["faaliyet_alanlari_baslik_1"]}</span>
                  </li>
                  <li
                    class={styleBtn === "acli2" ? "active  filter" : "filter"}
                    onClick={() => {
                      setStyleBtn("acli2");

                      setState({ ...state, filterKey: "AGREGA" });
                    }}
                  >
                    <span>{props.strings["faaliyet_alanlari_baslik_2"]}</span>
                  </li>
                  <li
                    class={styleBtn === "acli3" ? "active  filter" : "filter"}
                    onClick={() => {
                      setStyleBtn("acli3");

                      setState({ ...state, filterKey: "DEKAPAJ" });
                    }}
                  >
                    <span>{props.strings["faaliyet_alanlari_baslik_3"]} </span>
                  </li>
                  <li
                    class={styleBtn === "acli4" ? "active  filter" : "filter"}
                    onClick={() => {
                      setStyleBtn("acli4");

                      setState({ ...state, filterKey: "OTOYOL" });
                    }}
                  >
                    <span>{props.strings["faaliyet_alanlari_baslik_4"]}</span>
                  </li>
                  <li
                    class={styleBtn === "acli5" ? "active  filter" : "filter"}
                    onClick={() => {
                      setStyleBtn("acli5");

                      setState({ ...state, filterKey: "GECIT" });
                    }}
                  >
                    <span>{props.strings["faaliyet_alanlari_baslik_5"]}</span>
                  </li>
                  <li
                    class={styleBtn === "acli6" ? "active  filter" : "filter"}
                    onClick={() => {
                      setStyleBtn("acli6");

                      setState({ ...state, filterKey: "KOPRU" });
                    }}
                  >
                    <span>{props.strings["faaliyet_alanlari_baslik_6"]}</span>
                  </li>{" "}
                  <li
                    class={styleBtn === "acli7" ? "active  filter" : "filter"}
                    onClick={() => {
                      setStyleBtn("acli7");

                      setState({ ...state, filterKey: "TUNEL" });
                    }}
                  >
                    <span>{props.strings["faaliyet_alanlari_baslik_7"]}</span>
                  </li>{" "}
                  <li
                    class={styleBtn === "acli8" ? "active  filter" : "filter"}
                    onClick={() => {
                      setStyleBtn("acli8");

                      setState({ ...state, filterKey: "BARAJ" });
                    }}
                  >
                    <span>{props.strings["faaliyet_alanlari_baslik_8"]}</span>
                  </li>{" "}
                  <li
                    class={styleBtn === "acli9" ? "active  filter" : "filter"}
                    onClick={() => {
                      setStyleBtn("acli9");

                      setState({ ...state, filterKey: "TERMIK" });
                    }}
                  >
                    <span>{props.strings["faaliyet_alanlari_baslik_9"]}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="auto-container">
            <div class="items-container row">
              {filteredList.map((image, index) => (
                <div class="col-lg-3 col-md-6 gallery-block-one" key={image.id}>
                  <div class="inner-box">
                    <div class="image">
                      <img src={image.imgUrl} alt="" />
                    </div>
                    <div class="caption-title">
                      <h5>{image.name}</h5>
                      <h3>
                        <Link
                          onClick={() => {
                            setphotoIndex(index);
                            setisOpen(true);
                          }}
                        >
                          {image.description}
                        </Link>
                      </h3>
                      <div class="view-project">
                        <Link
                          data-fancybox="example gallery"
                          onClick={() => {
                            setphotoIndex(index);
                            setisOpen(true);
                          }}
                          class="zoom-btn"
                        >
                          <span>+</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex].imgUrl}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => setisOpen(false)}
              onMovePrevRequest={() =>
                setphotoIndex((photoIndex + images.length - 1) % images.length)
              }
              onMoveNextRequest={() =>
                setphotoIndex((photoIndex + 1) % images.length)
              }
            />
          )}
        </div>
      </section>
    </>
  );
};

PortfolioFilter1.propTypes = {
  strings: PropTypes.object,
};

export default multilanguage(PortfolioFilter1);
