import React,  {useState} from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
 const Menu = ({strings}) => {

  return (
    <div
          class="collapse navbar-collapse show clearfix"
          id="navbarSupportedContent"
        >
          <ul class="navigation clearfix">
            <li>
              <Link to={"/"}> {strings["anasayfa"]}</Link>
            </li>
            <li class="dropdown">
              <Link to={"/hakkimizda"}> {strings["kurumsal"]}</Link>
              <ul>
                <li>
                  <Link to={"/hakkimizda"}>
                    {strings["hakkimizda"]}
                  </Link>
                </li>
                <li>
                  <Link to={"/kalite-politikamiz"}>
                    {strings["kalite_politikamiz_baslik"]}
                  </Link>
                </li>
                <li>
                  <Link to={"/kvkk"}>{strings["kvkk"]}</Link>
                </li>
                <li>
                  <Link to={"/prensiplerimiz"}>
                    {strings["prensiplerimiz"]}
                  </Link>
                </li>
                <li>
                  <Link to={"/referanslar"}>
                    {strings["referanslar"]}
                  </Link>
                </li>
                <li>
                  <Link to={"/e-sirket-bilgilerimiz"}>
                    {strings["e_sirket_bilgilerimiz"]}
                  </Link>
                </li>
                <li>
                  <Link to={"/yonetim-kadrosu"}>
                    {strings["yonetim_kadrosu"]}
                  </Link>
                </li>
                <li>
                  <a
                    target={"_blank"}
                    href={"http://ozciftay.com.tr/katalog.pdf"}
                  >
                    {strings["e_katalog_baslik"]}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <Link to={"/faaliyet-alanlari"}>
                {strings["faaliyet_alanlari"]}
              </Link>
            </li>
            <li>
              <Link to={"/makinaparki"}>
                {strings["makina_parki"]}
              </Link>
            </li>
            <li class="dropdown">
              <Link to={"/devam-eden-projelerimiz"}>
                {" "}
                {strings["projelerimiz"]}
              </Link>
              <ul>
                <li>
                  <Link to={"/devam-eden-projelerimiz"}>
                    {strings["devam_eden_projelerimiz"]}
                  </Link>
                </li>
                <li>
                  <Link to={"/tamamlanan-projelerimiz"}>
                    {strings["tamamlanan_projelerimiz"]}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to={"/iletisim"}>{strings["contact"]}</Link>
            </li>
           
          </ul>
        </div>  )
}
Menu.propTypes = {
  strings: PropTypes.object,
};

export default multilanguage(Menu);