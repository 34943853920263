import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import propTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";

const PortfolioFilter2 = (props) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [ongoingData, setOngoingData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://server.ozciftay.com.tr/ongoingTr"
        );
        if (!response.ok) {
          throw new Error("API request failed!");
        }
        const data = await response.json();
        setOngoingData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
  const uniqueCities = [...new Set(ongoingData.map((data) => data.name))];

  return (
    <>
      <section className="gallery-section">
        <div className="sortable-masonry">
          <div className="auto-container">
            <div className="row m-0 justify-content-md-between align-items-center">
              <div className="sec-title">
                <div className="sub-title">
                  {props.strings["devam_eden_projelerimiz"]}
                </div>
                <h2>{props.strings["projelerimiz"]}</h2>
              </div>
              {/* <!--Filter--> */}
              <div
                className="filters text-center wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <ul className="filter-tabs filter-btns">
                  <li
                    className={
                      selectedCategory === null ? "active filter" : "filter"
                    }
                    onClick={() => {
                      setSelectedCategory(null);
                    }}
                  >
                    <span>{props.strings["tümü"]}</span>
                  </li>
                  {uniqueCities.map((city) => (
                    <li
                      key={city}
                      className={
                        selectedCategory === city ? "active filter" : "filter"
                      }
                      onClick={() => {
                        setSelectedCategory(city);
                      }}
                    >
                      <span>{city}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="auto-container">
            <div className="items-container row">
              {ongoingData
                .filter(
                  (data) =>
                    selectedCategory === null || data.name === selectedCategory
                )
                .map((data) => (
                  <div
                    className="col-lg-4 col-md-6 gallery-block-one"
                    key={data.id}
                  >
                    <div className="inner-box">
                      <div className="image">
                        <img
                          src={`https://server.ozciftay.com.tr/uploads/${data.img}`}
                          alt=""
                          className="img-fluid"
                          style={{ minHeight: "280px" }}
                        />
                      </div>
                      <div className="caption-title">
                        <h5>{data.name}</h5>
                        <h3>
                         {data.title}
                        </h3>
                        
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
PortfolioFilter2.propTypes = {
  strings: propTypes.object,
};

export default multilanguage(PortfolioFilter2);
