import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Brand1 from "../element/brand1";
import PortfolioFilter3 from "../element/PortfolioFilter3";
import propTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";

const aboutbg = require("./../../assets/images/background/projelerimiz.jpg");

class OngoingProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: props.strings,
    };
  }

  render() {
    return (
      <>
        <Header />

        <section class="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div class="bottom-rotten-curve alternate"></div>

          <div class="auto-container">
            <h1> {this.props.strings["tamamlanan_projelerimiz"]}</h1>
            <ul class="bread-crumb clearfix">
              <li style={{ textTransform: "capitalize" }}>
                <Link to={"/#"}>{this.props.strings["anasayfa"]}</Link>
              </li>
              <li style={{ textTransform: "capitalize" }} class="active">
                {this.props.strings["tamamlanan_projelerimiz"]}
              </li>
            </ul>
          </div>
        </section>
        {/* <!--End Banner Section --> */}

        {/* <!-- Case Studies --> */}
        <PortfolioFilter3 />

        <Footer />
      </>
    );
  }
}
OngoingProject.propTypes = {
  strings: propTypes.object,
};

export default multilanguage(OngoingProject);
