export const FETCH_FAALIYETTR_SUCCESS = "FETCH_FAALIYETTR_SUCCESS";

const fetchFaaliyetTrSuccess = (faaliyetTr) => ({
    type: FETCH_FAALIYETTR_SUCCESS,
    payload: faaliyetTr,
});

export const fetchFaaliyetTr = (faaliyetTr) => {
    return (dispatch) => {
        dispatch(fetchFaaliyetTrSuccess(faaliyetTr));
    };
};
