import React from "react";

const arac_takip = () => {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Marka</th>
            <th>Model</th>
            <th>Plaka</th>
            <th>Araç Tipi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Mercedes</td>
            <td>A Class</td>
            <td>A-123</td>
            <td>Sedan</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default arac_takip;
