import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { multilanguage } from "redux-multilanguage";
import PropTypes from "prop-types";

const aboutbg = require("./../../assets/images/background/image-11.jpg");
const wellcomebg1 = require("./../../assets/images/resource/image-1.jpg");

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: props.strings,
    };
  }

  render() {
    return (
      <>
        <Header />

        {/* <!-- Page Banner Section --> */}
        <section class="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div class="bottom-rotten-curve alternate"></div>

          <div class="auto-container">
            <h1> {this.props.strings["yonetim_kadrosu"]}</h1>
            <ul class="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>{this.props.strings["anasayfa"]}</Link>
              </li>
              <li class="active text-lowercase">
                {this.props.strings["yonetim_kadrosu"]}
              </li>
            </ul>
          </div>
        </section>
      

        {/* <!-- Team Section --> */}
        <section class="team-section gray-bg">
          <div class="auto-container">
            <div class="sec-title text-center">
              <div class="sub-title"> Özçiftay</div>
              <h2>{this.props.strings["yonetim_kadromuz"]}</h2>
            </div>
            <div class="row">
              {/* <!-- Team Block One --> */}
              <div class="col-lg-2 team-block-one">
               
              </div>
            
              {/* <!-- Team Block One --> */}
              <div class="col-lg-4 team-block-one">
                <div class="inner-box">
                  <div class="image">
                  
                  </div>
                  <div class="content">
                    <div class="designation">{this.props.strings["team_member_yonetim"]} </div>
                    <h3>Özgür Çiftci</h3>
                  
                  </div>
                </div>
              </div>
              {/* <!-- Team Block One --> */}
              <div class="col-lg-4 team-block-one">
                <div class="inner-box">
                  <div class="image">
                    {/* <img
                      src={require("../../assets/images/resource/team-2.jpg")}
                      alt=""
                    /> */}
                  </div>
                  <div class="content">
                    <div class="designation">{this.props.strings["team_member_yonetim"]} </div>
                    <h3>Önder Çiftci</h3>
                    
                  </div>
                </div>
              </div>
              <div class="col-lg-2 team-block-one"></div>
              {/* <!-- Team Block One --> */}
              <div class="col-lg-4 team-block-one">
             
              </div>
              {/* <!-- Team Block One --> */}
              <div class="col-lg-4 team-block-one mt-5">
                <div class="inner-box">
                 
                  <div class="content">
                    <div class="designation">{this.props.strings["team_member_mudur"]}</div>
                   
                  </div>
                </div>
              </div>
              <div class="col-lg-4 team-block-one">
             
              </div>
            </div>
            <div class="row">
              {/* <!-- Team Block One --> */}
              <div class="col-lg-4 team-block-one mt-5">
                <div class="inner-box">
                
                  <div class="content">
                    <div class="designation">{this.props.strings["team_member_muhasabe"]}</div>
                   
                  </div>
                </div>
              </div>
              {/* <!-- Team Block One --> */}
              <div class="col-lg-4 team-block-one mt-5">
                <div class="inner-box">
               
                  <div class="content">
                    <div class="designation">{this.props.strings["team_member_insan_kaynakları"]}</div>
                  
                  </div>
                </div>
              </div>
              {/* <!-- Team Block One --> */}
              <div class="col-lg-4 team-block-one mt-5">
                <div class="inner-box">
              
                  <div class="content">
                    <div class="designation">{this.props.strings["team_member_satin"]}</div>
                   
                  </div>
                </div>
              </div>
          
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

Team.propTypes = {
  strings: PropTypes.object,
};
export default multilanguage(Team);
