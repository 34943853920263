import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Brand1 from "../element/brand1";
import propTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const aboutbg = require("./../../assets/images/background/kalite_politikamiz.jpg");
const images = [
  "/assets/images/resource/9001.jpg",
  "/assets/images/resource/14001.jpg",
  "/assets/images/resource/45001.jpg",
  "/assets/images/resource/10002.jpg",

];

const images2 = [
  "/assets/images/resource/9001.jpg",
  "/assets/images/resource/14001.jpg",
  "/assets/images/resource/45001.jpg",
  "/assets/images/resource/10002.jpg",
];
const images3 = [
  "/assets/images/resource/9001.jpg",
  "/assets/images/resource/14001.jpg",
  "/assets/images/resource/45001.jpg",
  "/assets/images/resource/10002.jpg",
];
const images4 = [
  "/assets/images/resource/9001.jpg",
  "/assets/images/resource/14001.jpg",
  "/assets/images/resource/45001.jpg",
  "/assets/images/resource/10002.jpg",
];

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: props.strings,
      photoIndex1: 0,
      photoIndex2: 0,
      photoIndex3: 0,
      photoIndex4: 0,
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false,
    };
  }

  render() {
    const { photoIndex1, photoIndex2, photoIndex3, photoIndex4, isOpen1, isOpen2, isOpen3, isOpen4 } =
      this.state;
    return (
      <>
        <Header />

        <section class="page-banner">
          <div
            className="page-banner-bg"
            style={{
              backgroundImage: "url(" + aboutbg + ")",
              backgroundColor: "black",
            }}
          ></div>
          <div class="bottom-rotten-curve alternate"></div>

          <div class="auto-container">
            <h1>{this.props.strings["kalite_politikamiz"]}</h1>
            <ul class="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>{this.props.strings["anasayfa"]}</Link>
              </li>
              <li class="active">{this.props.strings["kalite_politikamiz"]}</li>
            </ul>
          </div>
        </section>

        <div className="d-flex justify-content-around my-5">
  <div className="col-xl-2"> </div>

  <div className="col-xl-2">
    <a type="button" onClick={() => this.setState({ isOpen1: true })}>
      <img
        alt="kalite-yönetim-sertifikası.jpg"
        style={{ width: "200px" }}
        src="/assets/images/resource/9001.jpg"
      ></img>
    </a>
  </div>

  <div className="col-xl-2">
    <a type="button" onClick={() => this.setState({ isOpen2: true })}>
      <img
        alt="çevre-yönetim-siste-sertifikası.jpg"
        style={{ width: "200px" }}
        src="/assets/images/resource/14001.jpg"
      ></img>
    </a>
  </div>

  <div className="col-xl-2">
    <a type="button" onClick={() => this.setState({ isOpen3: true })}>
      <img
        alt="iş-sağlığı-ve-güvenliği-sertifikası.jpg"
        style={{ width: "200px" }}
        src="/assets/images/resource/45001.jpg"
      ></img>
    </a>
  </div>

  <div className="col-xl-2">
    <a type="button" onClick={() => this.setState({ isOpen4: true })}>
      <img
        alt="yeni-sertifika.jpg"
        style={{ width: "200px" }}
        src="/assets/images/resource/10002.jpg"
      ></img>
    </a>
  </div>

  <div className="col-xl-2"> </div>

  {isOpen1 && (
    <Lightbox
      mainSrc={images[photoIndex1]}
      nextSrc={images[(photoIndex1 + 1) % images.length]}
      prevSrc={images[(photoIndex1 + images.length - 1) % images.length]}
      onCloseRequest={() =>
        this.setState({ isOpen1: false, photoIndex1: 0 })
      }
      onMovePrevRequest={() =>
        this.setState({
          photoIndex1: (photoIndex1 + images.length - 1) % images.length,
        })
      }
      onMoveNextRequest={() =>
        this.setState({ photoIndex1: (photoIndex1 + 1) % images.length })
      }
    />
  )}
  {isOpen2 && (
    <Lightbox
      mainSrc={images2[photoIndex2]}
      nextSrc={images2[(photoIndex2 + 1) % images2.length]}
      prevSrc={images2[(photoIndex2 + images2.length - 1) % images2.length]}
      onCloseRequest={() =>
        this.setState({ isOpen2: false, photoIndex2: 0 })
      }
      onMovePrevRequest={() =>
        this.setState({
          photoIndex2: (photoIndex2 + images2.length - 1) % images2.length,
        })
      }
      onMoveNextRequest={() =>
        this.setState({ photoIndex2: (photoIndex2 + 1) % images2.length })
      }
    />
  )}
  {isOpen3 && (
    <Lightbox
      mainSrc={images3[photoIndex3]}
      nextSrc={images3[(photoIndex3 + 1) % images3.length]}
      prevSrc={images3[(photoIndex3 + images3.length - 1) % images3.length]}
      onCloseRequest={() =>
        this.setState({ isOpen3: false, photoIndex3: 0 })
      }
      onMovePrevRequest={() =>
        this.setState({
          photoIndex3: (photoIndex3 + images3.length - 1) % images3.length,
        })
      }
      onMoveNextRequest={() =>
        this.setState({ photoIndex3: (photoIndex3 + 1) % images3.length })
      }
    />
  )}
  {isOpen4 && (
    <Lightbox
      mainSrc={images4[photoIndex4]}
      nextSrc={images4[(photoIndex4 + 1) % images4.length]}
      prevSrc={images4[(photoIndex4 + images4.length - 1) % images4.length]}
      onCloseRequest={() =>
        this.setState({ isOpen4: false, photoIndex4: 0 })
      }
      onMovePrevRequest={() =>
        this.setState({
          photoIndex4: (photoIndex4 + images4.length - 1) % images4.length,
        })
      }
      onMoveNextRequest={() =>
        this.setState({ photoIndex4: (photoIndex4 + 1) % images4.length })
      }
    />
  )}
</div>


        {/* History Section */}
        <section class="history-section">
          <div class="auto-container">
            <div class="sec-title text-center light">
              <div class="sub-title">{this.props.strings["history"]}</div>
              <h2>{this.props.strings["politikalarimiz"]}</h2>
            </div>
            <Tab.Container defaultActiveKey="first">
              <Nav className="nav-tabs tab-btn-style-one">
                <Nav.Item>
                  <Nav.Link eventKey="first">
                    {this.props.strings["kalite"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">
                    {" "}
                    {this.props.strings["cevre"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">
                    {" "}
                    {this.props.strings["iletisim"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth">
                    {" "}
                    {this.props.strings["istihdam"]}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fifth">
                    {" "}
                    {this.props.strings["halkla_iliskiler"]}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img src={"/assets/images/gallery/resim5.jpg"} alt="" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title light">
                          <div class="sub-title">
                            {this.props.strings["kalite_politikamiz_baslik"]}
                          </div>
                          {/* <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2> */}
                        </div>
                        <div class="text">
                          {ReactHtmlParser(
                            this.props.strings["kalite_politikamiz_icerik"]
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img src={"/assets/images/gallery/resim2.jpg"} alt="" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title light">
                          <div class="sub-title">
                            {" "}
                            {this.props.strings["cevre_politikamiz_baslik"]}
                          </div>
                        </div>
                        <div class="text">
                          {ReactHtmlParser(
                            this.props.strings["cevre_politikamiz_icerik"]
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img src={"/assets/images/gallery/resim1.jpg"} alt="" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title light">
                          <div class="sub-title">
                            {" "}
                            {this.props.strings["iletisim_politikamiz_baslik"]}
                          </div>
                          {/* <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2> */}
                        </div>
                        <div class="text">
                          {ReactHtmlParser(
                            this.props.strings["iletisim_politikamiz_icerik"]
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img src={"/assets/images/gallery/resim4.jpg"} alt="" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title light">
                          <div class="sub-title">
                            {" "}
                            {this.props.strings["istihdam_politikamiz_baslik"]}
                          </div>
                          {/* <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2> */}
                        </div>
                        <div class="text">
                          {ReactHtmlParser(
                            this.props.strings["istihdam_politikamiz_icerik"]
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fifth" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img src={"/assets/images/gallery/resim6.jpg"} alt="" />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title light">
                          <div class="sub-title">
                            {" "}
                            {
                              this.props.strings[
                                "halkla_iliskiler_politikamiz_baslik"
                              ]
                            }
                          </div>
                          {/* <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2> */}
                        </div>
                        <div class="text">
                          {ReactHtmlParser(
                            this.props.strings[
                              "halkla_iliskiler_politikamiz_icerik"
                            ]
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}
About.propTypes = {
  strings: propTypes.object,
};

export default multilanguage(About);
