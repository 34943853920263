import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import { multilanguage } from "redux-multilanguage";
import PropTypes from "prop-types";

import videobg2 from "./../../assets/images/background/videobackground2.jpg";

class VideoPopup2 extends Component {
  constructor(props) {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <>
        <section
          class="video-section video-section-none mb-5"
          style={{ backgroundImage: "url(" + videobg2 + ")" }}
        >
          <div class="auto-container">
            <div class="sec-title text-center light">
              <div class="sub-title">Özçiftay</div>
              <h2>
              {this.props.strings["index-video-baslik-1"]} <br />& <br />
              {this.props.strings["index-video-baslik-2"]}
              </h2>
            </div>
            <div
              class="default-video-box text-center wow zoomIn"
              data-wow-delay="200ms"
              data-wow-duration="1200ms"
            >
              <Link
                onClick={this.openModal}
                class="overlay-link lightbox-image video-fancybox ripple"
              >
                <span class="fas fa-play"></span>
              </Link>
            </div>
          </div>
        </section>

        <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="fyAahcIH_R0"
          onClose={() => this.setState({ isOpen: false })}
        />
      </>
    );
  }
}
VideoPopup2.propTypes = {
  strings: PropTypes.object,
};

export default multilanguage(VideoPopup2);