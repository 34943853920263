import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { multilanguage } from "redux-multilanguage";
import Brand1 from "../element/brand1";

const aboutbg = require("./../../assets/images/background/kvkk.jpg");
const touchbg = require("./../../assets/images/background/image-8.jpg");

class Kvkk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: props.strings,
    };
  }

  render() {
    return (
      <>
        <Header />

        {/* <!-- Page Banner Section --> */}
        <section class="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div class="bottom-rotten-curve alternate"></div>

          <div class="auto-container">
            <h1>KVKK</h1>
            <ul class="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>{this.props.strings["anasayfa"]}</Link>
              </li>
              <li class="active">{this.props.strings["kvkk"]}</li>
            </ul>
          </div>
        </section>
        {/* <!--End Banner Section --> */}

        {/* <!-- Services Section Five --> */}
        <section class="services-section-five gray-bg">
          <div class="auto-container">
            <Tab.Container defaultActiveKey="first">
              <Nav className="col-xl-12 nav-tabs tab-btn-style-one">
                <Nav.Item className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-6 mx-0">
                  <Nav.Link eventKey="first">
                    <div
                      class="icon-box"
                      style={{ minHeight: "300px !important" }}
                    >
                      <div class="icon">
                        <span class="flaticon-team"></span>
                      </div>
                      <h5 style={{ textTransform: "capitalize" }}>
                        {this.props.strings["aydinlatma_metni_kurum_disi"]}
                      </h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-6 mx-0">
                  <Nav.Link eventKey="second">
                    <div class="icon-box">
                      <div class="icon">
                        <span class="flaticon-money"></span>
                      </div>
                      <h5 style={{ textTransform: "capitalize" }}>
                        {this.props.strings["kameralar_icin_aydinlatma_metni"]}
                      </h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-6 mx-0">
                  <Nav.Link eventKey="third">
                    <div class="icon-box">
                      <div class="icon">
                        <span class="flaticon-assets"></span>
                      </div>
                      <h5 style={{ textTransform: "capitalize" }}>
                        {this.props.strings["basvuru_formu"]}
                      </h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-6 mx-0">
                  <Nav.Link eventKey="fourth">
                    <div class="icon-box">
                      <div class="icon">
                        <span class="flaticon-world"></span>
                      </div>
                      <h5 style={{ textTransform: "capitalize" }}>
                        {
                          this.props.strings[
                            "kisisel_verilerin_korunmasi_politikasi"
                          ]
                        }
                      </h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-6 mx-0">
                  <Nav.Link eventKey="fifth">
                    <div class="icon-box">
                      <div class="icon">
                        <span class="flaticon-money-1"></span>
                      </div>
                      <h5 style={{ textTransform: "capitalize" }}>
                        {
                          this.props.strings[
                            "ozel_nitelikli_kisisel_verilerin_korunmasi_politikasi"
                          ]
                        }
                      </h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-6 mx-0">
                  <Nav.Link eventKey="sixth">
                    <div class="icon-box">
                      <div class="icon">
                        <span class="flaticon-notebook"></span>
                      </div>
                      <h5 style={{ textTransform: "capitalize" }}>
                        {
                          this.props.strings[
                            "kisisel_verilerin_saklanmasi_ve_imha_edilmesi"
                          ]
                        }
                      </h5>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img
                          src="/assets/images/gallery/list/kvkk/kvkk570x480.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title">
                          <div class="sub-title">
                            {this.props.strings["aydinlatma_metni_kurum_disi"]}{" "}
                          </div>
                        </div>
                      
                        <div class="link-btn">
                          <a
                            href={
                              "/assets/pdf/1_aydınlatma_metni_kurum_disi.pdf"
                            }
                            class="theme-btn btn-style-one"
                          >
                            <span class="btn-title">
                            {this.props.strings["kvkk_buton"]}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img
                          src="/assets/images/gallery/list/kvkk/camera.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title">
                          <div class="sub-title">
                            {
                              this.props.strings[
                                "kameralar_icin_aydinlatma_metni"
                              ]
                            }
                          </div>
                        </div>
                       
                        <div class="link-btn">
                          <a
                            href={
                              "/assets/pdf/2_kameralar_icin_aydinlatma_metni.pdf"
                            }
                            class="theme-btn btn-style-one"
                          >
                            <span class="btn-title">
                            {this.props.strings["kvkk_buton"]}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img
                          src="/assets/images/gallery/list/kvkk/aplication_form.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title">
                          <div class="sub-title">
                            {this.props.strings["basvuru_formu"]}
                          </div>
                        </div>
                       
                        <div class="link-btn">
                          <a
                            href={"/assets/pdf/3_basvuru_formu.pdf"}
                            class="theme-btn btn-style-one"
                          >
                            <span class="btn-title">
                            {this.props.strings["kvkk_buton"]}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img
                          src="/assets/images/gallery/list/kvkk/personal_data.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title">
                          <div class="sub-title">
                            {
                              this.props.strings[
                                "kisisel_verilerin_korunmasi_politikasi"
                              ]
                            }
                          </div>
                        </div>
                       
                        <div class="link-btn">
                          <a
                            href={
                              "/assets/pdf/4_kişisel_verilerin_korunması_politikasi_kurum_disi.pdf"
                            }
                            class="theme-btn btn-style-one"
                          >
                            <span class="btn-title">
                            {this.props.strings["kvkk_buton"]}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="fifth" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img
                          src="/assets/images/gallery/list/kvkk/private_personal_data.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title">
                          <div class="sub-title">
                            {
                              this.props.strings[
                                "kisisel_verilerin_saklanmasi_ve_imha_edilmesi"
                              ]
                            }
                          </div>
                        </div>
                      
                        <div class="link-btn">
                          <a
                            href={
                              "/assets/pdf/5_özel_nitelikli_kişisel_verilerin_korunması_politikasi.pdf"
                            }
                            class="theme-btn btn-style-one"
                          >
                            <span class="btn-title">
                            {this.props.strings["kvkk_buton"]}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="sixth" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img
                          src="/assets/images/gallery/list/kvkk/sonuncu.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title">
                          <div class="sub-title">
                            {
                              this.props.strings[
                                "kisisel_verilerin_saklanmasi_ve_imha_edilmesi"
                              ]
                            }
                          </div>
                        </div>
                        
                        <div class="link-btn">
                          <a
                            href={
                              "/assets/pdf/6_kisisel_veri_saklama_ve_imha_politikasi.pdf"
                            }
                            class="theme-btn btn-style-one"
                          >
                            <span class="btn-title">
                            {this.props.strings["kvkk_buton"]}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}
export default multilanguage(Kvkk);
