import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import propTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import ReactHtmlParser from "react-html-parser";

const FaaliyetAlanlariAccordion = ({ strings, currentLanguageCode }) => {
  const [accordionAll, setAccordion] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          currentLanguageCode === "tr"
            ? "https://server.ozciftay.com.tr/faaliyetTr"
            : "https://server.ozciftay.com.tr/faaliyetEn"
        );
        if (!response.ok) {
          throw new Error("API request failed!");
        }
        const data = await response.json();
        const accordionItems = data.map((value, index) => (
          <AccordionItem key={index}>
            <AccordionItemHeading>
              <AccordionItemButton>{value.baslik}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {ReactHtmlParser(value.icerik)}
            </AccordionItemPanel>
          </AccordionItem>
        ));
        setAccordion(accordionItems);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentLanguageCode]); // Run effect whenever currentLanguageCode changes

  return (
    <section className="faq-section">
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-5">
            <div className="content mb-5 mr-lg-5">
              <div className="sec-title">
                <div className="sub-title">Özçiftay</div>
                <h2>{strings.faaliyet_alanlari}</h2>
                <div className="text">
                  {/* Veniam quis nostrud exercitation ullamco laboris nist aute
                  irure dolor in reprehenderit in voluptate velit esse nulla
                  pariatur excepteur sint occaecat aboris nisi ut aliquip ex
                  ea consequat uis aute. */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <Accordion preExpanded={[0]} allowZeroExpanded>
              {accordionAll}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

FaaliyetAlanlariAccordion.propTypes = {
  strings: propTypes.object,
};

export default multilanguage(FaaliyetAlanlariAccordion);
