import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import DataEn from "../../data/eCompanyEn.json";
import DataTr from "../../data/eCompanyTr.json";
import propTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import ReactHtmlParser from "react-html-parser";

 const ECompanyAccordion = ({strings,currentLanguageCode}) => {
   const [accordionAll, setAccordion] = useState([]);
   useEffect(()=> {
    let data = currentLanguageCode === "tr" ? DataTr : DataEn;
    let accordionAll = data.map((value, index) => {
      return (
        <AccordionItem key={index}>
          <AccordionItemHeading>
            <AccordionItemButton>{value.baslik}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>{ReactHtmlParser(value.icerik)}</p>
          </AccordionItemPanel>
        </AccordionItem>
      );
    });
    setAccordion(accordionAll);
   })
  return (
    <section class="faq-section">
    <div class="auto-container">
      <div class="row">
        <div class="col-lg-6">
          <Accordion preExpanded={[0]} allowZeroExpanded>
            {accordionAll.slice(
              accordionAll.length / 2,
              accordionAll.length
            )}
          </Accordion>
        </div>
        <div class="col-lg-6">
          <Accordion preExpanded={[0]} allowZeroExpanded>
            {accordionAll.slice(
              0,
              accordionAll.length / 2
            )}
          </Accordion>
        </div>
      </div>
    </div>
  </section>
  )
}
ECompanyAccordion.propTypes = {
  strings: propTypes.object,
};

export default multilanguage(ECompanyAccordion);