import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import GoogleMaps from "simple-react-google-maps";
import propTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const aboutbg = require("./../../assets/images/background/contact.jpg");
const { REACT_APP_HOST, REACT_APP_USERNAME, REACT_APP_PASSWORD } =
  process.env;
const Contact = (props) => {
  const [nameSurname, setNameSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [imageFile, setImageFile] = useState("");

  const onSubmit = (e)=>{
    e.preventDefault();
    if(nameSurname === "" || email === "" || phone === "" || message === ""){
      toast.warning("Lütfen Tüm Alanları Doldurunuz");
    }else{
    const formData = new FormData();
    
    formData.append("nameSurname", nameSurname);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("message", message);
    formData.append("imageFile", imageFile);

    axios
    .post(
      `${REACT_APP_HOST}hr/hr-form`,
      formData,
      {
        auth: {
          username: REACT_APP_USERNAME,
          password: REACT_APP_PASSWORD,
        },
      }
    )
    .then((res) => {
      toast.success(res.data.responseMessage, { autoClose: 3500 });
      setTimeout(() => {
        window.location.reload();
      }, 3500);
    })
    .catch((err) => {
      if (err.request.response == "") {
        toast.error("Sunucu ile bağlantı kurulamadı.");
      } else {
        toast.error(err.response.data.responseMessage);
      }
    });
  }

  }

  return (
    <>
      <Header />

      {/* <!-- Page Banner Section --> */}
      <section class="page-banner">
        <div
          className="page-banner-bg"
          style={{ backgroundImage: "url(" + aboutbg + ")" }}
        ></div>
        <div class="bottom-rotten-curve alternate"></div>

        <div class="auto-container">
          <h1>{props.strings["career"]}</h1>
          <ul class="bread-crumb clearfix">
            <li>
              <Link to={"/#"}>{props.strings["anasayfa"]}</Link>
            </li>
            <li class="active">{props.strings["career"]}</li>
          </ul>
        </div>
      </section>

      {/* <!-- Services Section Two --> */}
      <section class="services-section-two services-section-careers">
        <div class="auto-container">
          <div class="wrapper-box">
            <div class="left-column">
              <div class="sec-title light">
                <div class="sub-title">{props.strings["career"]}</div>
                <h4>{props.strings["career_title"]}</h4>
              </div>
              {/* <!-- Contact Form--> */}
              <div class="contact-form">
                <form onSubmit={onSubmit} id="contact-form">
                  <div class="row clearfix">
                    <div class="col-md-12 form-group">
                      <input
                        type="text"
                        name="username"
                        value={nameSurname}
                        onChange={(e) => setNameSurname(e.target.value)}
                        placeholder={props.strings["adiniz_soyadiniz"]}
                        required=""
                      />
                      <i class="fas fa-user"></i>
                    </div>

                    <div class="col-md-12 form-group">
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={props.strings["eposta_adresiniz"]}
                        required=""
                      />
                      <i class="fas fa-envelope"></i>
                    </div>
                    <div class="col-md-12 form-group">
                      <input
                        type="email"
                        name="email"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder={props.strings["telefon_numarasi"]}
                        required=""
                      />
                      <i class="fas fa-phone"></i>
                    </div>

                    <div class="col-md-12 form-group">
                      <textarea
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder={props.strings["mesajiniz"]}
                      ></textarea>
                      <i class="fas fa-edit"></i>
                    </div>
                    <div class="col-md-12 form-group ">
                      <input
                        type="file"
                        name="email"
                        onChange={(e) => setImageFile(e.target.files[0])}
                        style={{ background: "#e6e6e6" }}
                        required=""
                      />
                    </div>
                    <div class="col-md-12 form-group">
                      <button
                        class="theme-btn btn-style-one"
                        onClick={onSubmit}
                        name="submit-form"
                      >
                        <span class="btn-title">{props.strings["gonder"]}</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="right-column">
              <div class="services-content">
                <div class="sec-title">
                  <div class="sub-title">{props.strings["biz_kimiz"]}</div>
                </div>
                <div class="icon-box">
                  <div class="text">{props.strings["career_aciklama_1"]}</div>
                </div>
                <div class="icon-box">
                  <div class="text">{props.strings["career_aciklama_2"]}</div>
                </div>
                <div class="icon-box">
                  <div class="text">{props.strings["career_aciklama_3"]}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer />
      <Footer />
    </>
  );
};
Contact.propTypes = {
  strings: propTypes.object,
};

export default multilanguage(Contact);
