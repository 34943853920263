import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers/rootReducer";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import faaliyetEn from "./data/faaliyetAlanlariEn.json"
import faaliyetTr from "./data/faaliyetAlanlariTr.json"
import { fetchFaaliyetEn } from "./redux/actions/faaliyetEnAction";
import { fetchFaaliyetTr } from "./redux/actions/faaliyetTrAction";
import { composeWithDevTools } from "redux-devtools-extension";

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);
store.dispatch(fetchFaaliyetEn(faaliyetEn));
store.dispatch(fetchFaaliyetTr(faaliyetTr));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
