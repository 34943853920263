export const FETCH_FAALIYETEN_SUCCESS = "FETCH_FAALIYETEN_SUCCESS";

const fetchFaaliyetEnSuccess = (faaliyetEn) => ({
    type: FETCH_FAALIYETEN_SUCCESS,
    payload: faaliyetEn,
});

export const fetchFaaliyetEn = (faaliyetEn) => {
    return (dispatch) => {
        dispatch(fetchFaaliyetEnSuccess(faaliyetEn));
    };
};
