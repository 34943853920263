import React, { useRef  } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import GoogleMaps from "simple-react-google-maps";
import propTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const aboutbg = require("./../../assets/images/background/contact.jpg");

 const Contact = (props) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_q5001su",
        "template_m61yber",
        form.current,
        "--n2RwqKSVsQP0nKl"
      )
      .then((result) => {
        if (result.status === 200) {
          toast.success("İletişim Formunuz Başarıyla Gönderildi");
        } else {
          toast.error("İletişim Formunuz Gönderilemedi");
        }
      })
      .catch((err) => {
        toast.error("İletişim Formunuz Gönderilemedi");
      });
  };
  return (
    <>

    <Header />

    {/* <!-- Page Banner Section --> */}
    <section class="page-banner">
      <div
        className="page-banner-bg"
        style={{ backgroundImage: "url(" + aboutbg + ")" }}
      ></div>
      <div class="bottom-rotten-curve alternate"></div>

      <div class="auto-container">
        <h1>{props.strings["bize_ulasin_baslik"]}</h1>
        <ul class="bread-crumb clearfix">
          <li>
            <Link to={"/#"}>{props.strings["anasayfa"]}</Link>
          </li>
          <li class="active">{props.strings["iletisim"]}</li>
        </ul>
      </div>
    </section>

    {/* <!-- Map Section --> */}
    {/* <!-- Map Section --> */}
    <section class="map-section">
      <div class="map-column d-flex justify-content-center">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3061.3558299580077!2d32.81828365138191!3d39.88866489540737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d345f5c3bb4e77%3A0x110ae7c65709a9b2!2zw5Zaw4fEsEZUQVkgQS7Fng!5e0!3m2!1str!2str!4v1648209132266!5m2!1str!2str"
          width={window.innerWidth}
          height={window.innerHeight / 1.5}
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
        />
      </div>
    </section>

    {/* <!-- Contact Section Two --> */}
    <section class="contact-section-two">
      <div class="auto-container">
        <div class="contact-info-area">
          <div class="contact-info">
            <div class="row">
              <div class="info-column col-lg-4">
                <div class="icon-box">
                  <div class="icon">
                    <span class="flaticon-email-6"></span>
                  </div>
                  <h3>{props.strings["eposta"]}</h3>
                  <ul>
                    <li>
                      <Link to={"/mailto:info@ozciftay.com.tr"}>
                        <br />{" "}
                        {props.strings["iletisim_merkez_eposta"]}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="info-column col-lg-4">
                <div class="icon-box">
                  <div class="icon">
                    <span class="flaticon-call-1"></span>
                  </div>
                  <h3>{props.strings["telefon"]}</h3>
                  <ul>
                    <li>
                      <Link to={"/tel:+90(312)4727677"}>
                        <br />{" "}
                        {props.strings["iletisim_merkez_telefon"]}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="info-column col-lg-4">
                <div class="icon-box">
                  <div class="icon">
                    <span class="flaticon-location"></span>
                  </div>
                  <h3>{props.strings["adres"]}</h3>
                  <ul>
                    <li>{props.strings["iletisim_merkez_adres"]}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contact-form-area">
          <div class="sec-title text-center">
            <div class="sub-title">{props.strings["iletisim"]}</div>
            <h2>{props.strings["bize_yazin"]}</h2>
          </div>
          {/* <!-- Contact Form--> */}
          <div class="contact-form">
          <form ref={form} onSubmit={sendEmail}>
              <div class="row clearfix">
                <div class="col-md-6 form-group">
                  <label for="name">
                    {props.strings["enter_your_name"]}
                  </label>
                  <input
                    type="text"
                    name="ad_soyad"
                    id="name"
                    placeholder={props.strings["adiniz_soyadiniz"]}
                    required=""
                  />
                  <i class="fas fa-user"></i>
                </div>

                <div class="col-md-6 form-group">
                  <label for="email">
                    {props.strings["enter_your_email"]}
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder={props.strings["eposta_adresiniz"]}
                    required=""
                  />
                  <i class="fas fa-envelope"></i>
                </div>

                <div class="col-md-12 form-group">
                  <label for="message">
                    {props.strings["enter_your_message"]}
                  </label>
                  <textarea
                     name="mesaj"
                    id="message"
                    placeholder={props.strings["mesajiniz"]}
                  ></textarea>
                  <i class="fas fa-edit"></i>
                </div>

                <div class="col-md-12 form-group">
                  <button
                    class="theme-btn btn-style-one"
                    type="submit"
                  >
                    <span class="btn-title">
                      {props.strings["gonder"]}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <ToastContainer/>
    <Footer />
    </>

  )
}
Contact.propTypes = {
  strings: propTypes.object,
};

export default multilanguage(Contact);
