import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import faaliyetEnReducer from "./faaliyetEnReducer";
import faaliyetTrReducer from "./faaliyetTrReducer";
const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "tr" }),
  faaliyetEnData: faaliyetEnReducer,
  faaliyetTrData: faaliyetTrReducer,
});

export default rootReducer;
