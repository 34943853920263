import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Index from "./pages/index";
import Error404 from "./pages/404";
import About from "./pages/about";
import References from "./pages/References";
import Contact from "./pages/contact";
import BackToTop from "./layout/backToTop";
import quality_policy from "./pages/quality_policy";
import kvkk from "./pages/kvkk";
import ourPrinciples from "./pages/our-principles";
import eCompany from "./pages/e-company";
import OngoingProject from "./pages/ongoingProjects";
import finishedProjects from "./pages/finishedProjects";
import MakinaParki from "./pages/makinaparki";
import faaliyetAlanları from "./pages/faaliyetAlanları";
import Team from "./pages/team";
import AracTakip from "./pages/arac_takip";

class Router extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        {/* <BrowserRouter> */}
        <div class="page-wrapper">
          <Switch>
            <Route exact path="/" component={Index} />
            <Route path="/kalite-politikamiz" component={quality_policy} />
            <Route path="/404" component={Error404} />
            <Route path="/hakkimizda" component={About} />
            <Route path="/referanslar" component={References} />
            <Route path="/iletisim" component={Contact} />
            <Route path="/kvkk" component={kvkk} />
            <Route path="/prensiplerimiz" component={ourPrinciples} />
            <Route path="/e-sirket-bilgilerimiz" component={eCompany} />
            <Route path="/devam-eden-projelerimiz" component={OngoingProject} />
            <Route path="/makinaparki" component={MakinaParki} />
            <Route path="/faaliyet-alanlari" component={faaliyetAlanları} />
            <Route path="/yonetim-kadrosu" component={Team} />
            <Route path="/arac-takip" component={AracTakip} />
            <Route
              path="/tamamlanan-projelerimiz"
              component={finishedProjects}
            />
            <Route exact component={Error404} />
          </Switch>
          <BackToTop />
        </div>
      </BrowserRouter>
    );
  }
}

export default Router;
