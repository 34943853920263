import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import { multilanguage } from "redux-multilanguage";
import PropTypes from "prop-types";

import  videobg1 from "./assets/images/background/videobackground1.jpg";

class VideoPopup1 extends Component {
  constructor(props) {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <>
        <section
          class="video-section"
          style={{
            backgroundImage: "url(" + videobg1 + ")",
          }}
        >
          <div class="default-video-box text-center wow zoomIn">
            <Link
              onClick={this.openModal}
              class="overlay-link lightbox-image video-fancybox ripple"
            >
              <span class="fas fa-play"></span>
            </Link>
          </div>
          <div class="sec-title light text-center">
            <div class="sub-title">Özçiftay</div>
            <h2>
              {this.props.strings["index-video-baslik-1"]} <br />& <br />
              {this.props.strings["index-video-baslik-2"]}
            </h2>
          </div>
        </section>

        <ModalVideo
          channel="youtube"
          autoplay={1}
          isOpen={this.state.isOpen}
          videoId="TWj7ATLMMY4"
          onClose={() => this.setState({ isOpen: false })}
        />
      </>
    );
  }
}
VideoPopup1.propTypes = {
  strings: PropTypes.object,
};

export default multilanguage(VideoPopup1);