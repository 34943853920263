import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { multilanguage } from "redux-multilanguage";
import ReactHtmlParser from "react-html-parser";
const aboutbg = require("./../../assets/images/background/hakkimizda.jpg");
// const casebg = require('./../../assets/images/background/image-14.jpg');

class OurPrinciples extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: props.strings,
    };
  }

  render() {
    return (
      <>
        <Header />

        <section class="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div class="bottom-rotten-curve alternate"></div>

          <div class="auto-container">
            <h1>{this.props.strings["prensiplerimiz"]}</h1>
            <ul class="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>{this.props.strings["anasayfa"]}</Link>
              </li>
              <li class="active">{this.props.strings["prensiplerimiz"]}</li>
            </ul>
          </div>
        </section>
        {/* <!-- Services Section Three --> */}
        <section class="services-section-three style-two">
          <div class="auto-container">
            <div class="row">
              {/* <!-- Services Block One --> */}
              <div class="service-block-one col-lg-3">
                <div class="inner-box" style={{ height: "425px" }}>
                  <div class="icon">
                    <span class="flaticon-team"></span>
                  </div>
                  <div class="text">
                    {this.props.strings["prensiplerimiz_icerik1"]}
                  </div>
                </div>
              </div>
              {/* <!-- Services Block One --> */}
              <div class="service-block-one col-lg-3">
                <div class="inner-box" style={{ height: "425px" }}>
                  <div class="icon">
                    <span class="flaticon-fast"></span>
                    <span class="round-shape"></span>
                  </div>
                  <div class="text">
                    {ReactHtmlParser(
                      this.props.strings["prensiplerimiz_icerik2"]
                    )}
                  </div>
                </div>
              </div>
              {/* <!-- Services Block One --> */}
              <div class="service-block-one col-lg-3">
                <div class="inner-box" style={{ height: "425px" }}>
                  <div class="icon">
                    <span class="flaticon-risk"></span>
                    <span class="round-shape"></span>
                  </div>
                  <div class="text">
                    {this.props.strings["prensiplerimiz_icerik3"]}
                  </div>
                </div>
              </div>
              {/* <!-- Services Block One --> */}
              <div class="service-block-one col-lg-3">
                <div class="inner-box" style={{ height: "425px" }}>
                  <div class="icon">
                    <span class="flaticon-world"></span>
                    <span class="round-shape"></span>
                  </div>
                  <div class="text">
                    {this.props.strings["prensiplerimiz_icerik4"]}
                  </div>
                </div>
              </div>
              {/* <!-- Services Block One --> */}
              <div class="service-block-one col-lg-3">
                <div class="inner-box" style={{ height: "425px" }}>
                  <div class="icon">
                    <span class="flaticon-analysis"></span>
                    <span class="round-shape"></span>
                  </div>
                  <div class="text">
                    {this.props.strings["prensiplerimiz_icerik5"]}
                  </div>
                </div>
              </div>
              {/* <!-- Services Block One --> */}
              <div class="service-block-one col-lg-3">
                <div class="inner-box" style={{ height: "425px" }}>
                  <div class="icon">
                    <span class="flaticon-loupe"></span>
                    <span class="round-shape"></span>
                  </div>
                  <div class="text">
                    {this.props.strings["prensiplerimiz_icerik6"]}
                  </div>
                </div>
              </div>
              <div class="service-block-one col-lg-3">
                <div class="inner-box" style={{ height: "425px" }}>
                  <div class="icon">
                    <span class="flaticon-network"></span>
                    <span class="round-shape"></span>
                  </div>
                  <div class="text">
                    {this.props.strings["prensiplerimiz_icerik7"]}
                  </div>
                </div>
              </div>{" "}
              <div class="service-block-one col-lg-3">
                <div class="inner-box" style={{ height: "425px" }}>
                  <div class="icon">
                    <span class="flaticon-worldwide"></span>
                    <span class="round-shape"></span>
                  </div>
                  <div class="text">
                    {this.props.strings["prensiplerimiz_icerik8"]}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Case Studies --> */}

        <Footer />
      </>
    );
  }
}
export default multilanguage(OurPrinciples);
